import request from '@/utils/request'
import store from '@/store'
import _g from '../../src/global'

const PerhitunganKinerjaService = {
  getAllData,
  getEditReviewer,
  getReviewer,
  setReviewer,
  autoGenerate,
  hapusReviewer,
  hapusMereview,
  getDetailPegawai,
  getDataLoginSKPD,
  getAllDataEmptyReviewerSKPD,
  getAvailableReviewer,
  getMereview,
  setMereview
}


// function getAllData(cari, row_per_page, page, satuan_kerja_id, params_sorts){
function getAllData(data){
  let current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  let sk_id = data.satuan_kerja_id ? data.satuan_kerja_id : 6


  return request({
    url:'/setting-reviewer-get-all-data',
    method:'post',
    data:{
    nip:current.username,
    "role": current.role,
    "order": "peg_nama",
    "order_direction": "asc",
    "page": data.page,
    "perpage": data.row_per_page,
    "search": data.search,
    "params_sorts": data.params_sorts,
    "params_satuan_kerja_id": sk_id
    }
  })
}

function getEditReviewer(nip, bulan_){
  let current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  let bulan = bulan_ ? bulan_:6

  return request({
    url:'/setting-reviewer-get-edit-reviewer',
    method:'post',
    data:{
      "peg_nip": nip,
      "bulan": bulan
    }
  })
}

function getReviewer(data){
  return request({
    url:'/setting-reviewer-get-reviewer',
    method:'post',
    data:{
    "peg_nip": data.nip,
    "bulan": data.bulan
    }
  })
}

function getAvailableReviewer(data){
  return request({
    url:'/setting-reviewer-get-available-reviewer',
    method:'post',
    data:{
    "peg_nip": data.nip,
    "bulan": data.bulan,
    "direction" : data.direction
    }
  })
}

function setReviewer(data){
  return request({
    url:'/setting-reviewer-set-reviewer-atas-kanan-bawah-kiri',
    method:'post',
    data:{
      peg_nip:data.peg_nip,
      direction:data.direction,
      nip_reviewer:data.nip_reviewer,
      bulan:data.bulan
    }
  })
}

function autoGenerate(data){
  return request({
    url:'/setting-reviewer-auto-generate-reviewer',
    method:'post',
    data:{
      peg_nip: data.peg_nip,
      bulan: data.bulan
    }
  })
}

function hapusReviewer(data){
  return request({
    url:'/setting-reviewer-hapus-reviewer',
    method:'post',
    data:{
      peg_nip:data.peg_nip,
      bulan:data.bulan
    }
  })
}

function hapusMereview(data){
  return request({
    url:'/setting-reviewer-hapus-mereview',
    method:'post',
    data:{
      nip:data.nip,
      nip_reviewer:data.nip_reviewer,
      bulan:data.bulan
    }
  })
}

function getDetailPegawai(){
  let current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url:'',
    method:'post',
    data:{
      nip:current.username,
    }
  })
}

function getDataLoginSKPD(){
  let current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url:'',
    method:'post',
    data:{
      nip:current.username,
    }
  })
}

function getAllDataEmptyReviewerSKPD(){
  let current = store.state.user.current
  let user = store.state.user
  if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
    current.username = _g.overrideWithPLT(user.current.role)
  }else{
    current.username = user.current.username
  }
  return request({
    url:'',
    method:'post',
    data:{
      nip:current.username,
    }
  })
}

function getMereview(data){
  return request({
    url:'/setting-reviewer-get-mereview',
    method:'post',
    data:{
    "nip": data.nip,
    "nip_reviewer": data.nip_reviewer,
    "bulan": data.bulan
    }
  })
}

function setMereview(data){
  return request({
    url:'/setting-reviewer-set-mereview',
    method:'post',
    data:{
    "nip": data.nip,
    "nip_reviewer": data.nip_reviewer,
    "nip_sebelum": data.nip_sebelum,
    "bulan": data.bulan
    }
  })
}

export default PerhitunganKinerjaService