<template lang="html">
  <v-container fluid>
    <v-row>
      <v-col>
        <base-material-card>
          <template v-slot:heading>
            <h3 class="display-2">Perhitungan Kinerja Bulan ...</h3>
          </template>
          <v-toolbar flat>
            <div>
              <v-text-field v-model="search" hide-details label="Cari" append-icon="mdi-magnify"></v-text-field>
            </div>
          </v-toolbar>

          <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            :server-items-length="totalKeseluruhan"
            :page.sync="page"
            :items-per-page.sync="rowPerPage"
            :footer-props="{'items-per-page-options':[10,15,20]}"
          >
            <template v-slot:item.no="{item, index}">
              {{index+1}}
            </template>
            <template v-slot:item.nip="{ item }">
              <br>
              <div>
                <b> {{item.peg_nama}} </b>
                <br>
                {{item.peg_nip}}
                <br>
                <b><font :color="item.status == 'Belum Komplit' ? 'red' : 'green'">{{item.status}}</font></b>
              </div>
              <br>
            </template>
            <template v-slot:item.jabatan="{ item }">
              <div>{{ item.jenis_jabatan == 'S' ? 'Struktural' : (item.jenis_jabatan == 'F' ? 'Fungsional Umum' : (item.jenis_jabatan == 'T' ? 'Fungsional Tertentu' : '')) }} {{ item.eselon  }}<br>{{ item.nama_golongan }}</div>
            </template>
            <template v-slot:item.job_value="{ item }">
              <div><b>{{item.job_value}}</b> <br> {{item.nama_jabatan}} <br> {{item.kelas_jabatan}}</div>
            </template>
            <template v-slot:item.mereview="{ item }">
              <div><b>{{item.mereview}}</b></div>
            </template>
            <template v-slot:item.direview="{ item }">
              <div><b>{{item.direview}}</b></div>
            </template>
            <template v-slot:item.aksi="{item}">
              <v-btn color="yellow darken-1" small @click="detailReviewer(item)"><v-icon small>mdi-magnify</v-icon>Detail</v-btn>&nbsp;
              <v-btn color="primary" small @click="editReviewer(item)"><v-icon small>mdi-pencil</v-icon>Edit</v-btn>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogDetailReviewer" max-width="900px">
      <v-card>
        <v-card-title>
          <h2>Detail Pegawai</h2>
        </v-card-title>
        <v-card-text class="ml-10">
          <v-row>
            <p><b>Nama: </b></p>&ensp;
            <p>{{ item.peg_nama }}</p>
          </v-row>
          <v-row>
            <p><b>NIP: </b></p>&ensp;
            <p>{{ item.peg_nip }}</p>
          </v-row>
          <v-row>
            <p><b>Jenis Jabatan: </b></p>&ensp;
            <p>{{ item.jenis_jabatan == 'S' ? 'Struktural' : (item.jenis_jabatan == 'F' ? 'Fungsional Umum' : (item.jenis_jabatan == 'T' ? 'Fungsional Tertentu' : ''))  }}</p>
          </v-row>
          <v-row>
            <p><b>Jabatan: </b></p>&ensp;
            <p>{{ item.nama_jabatan }}</p>
          </v-row>
          <v-row>
            <p><b>SOPD: </b></p>&ensp;
            <p>
              <template v-for="(uker, index) in item.uker">
                <template >
                  <div v-if="index == 0" v-bind:key="index">
                    <span>{{uker.nama}}</span><br>
                  </div>
                  <div v-if="index == 1" v-bind:key="index">
                    <span style="margin-left:20px">{{uker.nama}}</span><br>
                  </div>
                  <div v-if="index == 2" v-bind:key="index">
                    <span style="margin-left:40px">{{uker.nama}}</span><br>
                  </div>
                </template>
              </template>
              <template v-if="item.uker">
                <template v-if="item.uker.length == 0">
                  <span><b>{{item.satker}}</b></span>
                </template>
                <template v-if="item.uker.length == 1">
                  <span style="margin-left:20px"><b>{{item.satker}}</b></span>
                </template>
                <template v-if="item.uker.length == 2">
                  <span style="margin-left:40px"><b>{{item.satker}}</b></span>
                </template>
                <template v-if="item.uker.length == 3">
                  <span style="margin-left:60px"><b>{{item.satker}}</b></span>
                </template>
              </template>
            </p>
          </v-row>
          <v-row v-show="item.jenis_jabatan == 'S'">
            <p><b>Eselon: </b></p>&ensp;
            <p>{{ item.eselon }}</p>
          </v-row>
          <v-row>
            <p><b>Golongan: </b></p>&ensp;
            <p>{{ item.nama_golongan }}</p>
          </v-row>
          <v-row>
            <p><b>Job Value: </b></p>&ensp;
            <p>{{ item.job_value }} ({{ item.kelas_jabatan }})</p>
          </v-row>
          <v-row>
            <p><b></b></p>
            <p><em>Apabila terdapat ketidaksesuaian data, silakan hubungi Admin SIMPEG untuk memperbarui data</em></p>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="yellow darken-1" @click="dialogDetailReviewer = false">Tutup</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogPegawaiReviewer" persistent>
      <!-- <PegawaiReviewer :pegawai="dataPegawai" @onClose="(x)=>{dialogPegawaiReviewer=x}" @onTableChange="(x)=>updateTable(x)"/> -->
    </v-dialog>
  </v-container>
</template>

<script>
import PerhitunganKinerjaService from '@/services/PerhitunganKinerjaService'
// import PegawaiReviewer from './PegawaiReviewer.vue'
import store from '@/store'
import _g from '../../global'
import axios from 'axios'
import {local} from '@/store/local'
import _ from 'lodash'
export default {
  components:{
    // PegawaiReviewer
  },
  data(){
    return{
      filterPegawai:true,
      forceUpdate:false,
      loading:false,
      detailData:{},
      skpd:[],
      headers:[
        {text:'Informasi Pegawai', value:'informasi_pegawai', sortable:false, class:'header-index-profile'},
        {text:'Job Value', value:'job_value', sortable:false, class:'header-index-profile'},
        {text:'Aktivitas', value:'aktivitas', sortable:false, class:'header-index-profile'},
        {text:'Nilai Aktivitas', value:'nilai_aktivitas', sortable:false, class:'header-index-profile'},
        {text:'Perilaku', value:'perilaku', sortable:false, class:'header-index-profile'},
        {text:'Total', value:'total', sortable:false, class:'header-index-profile'},
        {text:'Rupiah', value:'rupiah', sortable:false, class:'header-index-profile'},
        {value:'aksi', sortable:false, class:'header-index-profile', width:"20%"},
      ],
      dialogReset:false,
      crudSettings:{
        title:'',
        detailTitle:'Detail',
        enableHeader:true,
        enableDetail:false,
        enableCreate:false,
      },
      selectSKPD:'',
      search: '',
      tableValue:{},
      totalKeseluruhan:0,
      rowPerPage:10,
      page:1,
      dialogDetailReviewer: false,
      item:{},
      dialogPegawaiReviewer: false,
      dataPegawai: {}
    }
  },

  watch:{
    page(val){
      this.tableValue.page = val
      this.updateTable(this.tableValue)
    }
  },

  mounted(){
    this.$watch(vm => [vm.rowPerPage, vm.search, vm.selectSKPD, vm.selectBulan, vm.filterPegawai], val => {
      this.page = 1
      this.updateTable({itemsPerPage:this.rowPerPage, satuan_kerja_id:this.selectSKPD, search:val[1], page:this.page, params_sorts:this.filterPegawai})
    })
  },

  created(){
    var param = local.getLocal('pk_skpd')
    console.log('Route Params', this.$route.params)
    debugger
    var tes = this.$route.params
    let val = {itemsPerPage:10, page: 1}
    this.updateTable(val)
    this.getListSKPD()
  },

  computed:{
    filterSemua:{
      get:function(){
        return !this.filterPegawai
      },
      set:function(val){
        this.filterPegawai = !val
      }
    }
  },

  methods:{
    getListSKPD(){
      var url = "v1/get-data-skpd-satuan-kerja"
      let current = store.state.user.current
      let user = store.state.user
      if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
        current.username = _g.overrideWithPLT(user.current.role)
      }else{
        current.username = user.current.username
      }
      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_ = base_url_api + url
      axios.post(url_).then(response => {
        let data = []
        _.each(response.data.data, function(value, key, obj){
          data.push({k:key, v: value})
        })
        this.skpd = data
      }).catch(err => {
        var error = err.message
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    updateTable(val){
      var filter = 'erk'
      if(this.filterSemua){
        filter = 'all'
      }

      this.loading = true
      this.tableValue = val ? val: this.tableValue
      this.items = []

      var params = {}
      params.kolok = this.tableValue.kode_skpd ? this.tableValue.kode_skpd : null
      params.bulan = this.tableValue.bulan ? this.tableValue.bulan : null
      var url = "v1/perhitungan-kinerja/get-list"
      let current = store.state.user.current
      let user = store.state.user
      if(user.current.role.includes("PLT") || user.current.role.includes("Kepala UPT Puskesmas") || user.current.role.includes("PLH")){
        current.username = _g.overrideWithPLT(user.current.role)
      }else{
        current.username = user.current.username
      }
      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_ = base_url_api + url
      axios.post(url_, {
        "api":1,
        "satuan_kerja_id":current.username,
        "unit_kerja_id":current.username
      }).then(response => {
        let data = []
        _.each(response.data.data, function(value, key, obj){
          data.push({k:key, v: value})
        })
        this.skpd = data
      }).catch(err => {
        var error = err.message
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    //   PerhitunganKinerjaService.getAllData({
    //     search:this.tableValue.search,
    //     row_per_page:this.tableValue.itemsPerPage,
    //     page:this.tableValue.page,
    //     satuan_kerja_id:this.selectSKPD,
    //     params_sorts: filter})
    //   .then((response)=>{
    //     this.items = response.data.data
    //     this.totalKeseluruhan = response.data.total_keseluruhan
    //     console.log(this.items)
    //     let res = response.data
    //     if (res.status == 200){
    //       store.commit('snackbar/setSnack',{message: (res.message ? res.message : 'Get Data Berhasil'), color:'success'})
    //     } else {
    //       store.commit('snackbar/setSnack',{message: res.message, color:'error'})
    //     }
    //   }).finally(()=>{
    //     this.loading = false
    //   })
    },

    detailReviewer(item){
      this.item = item
      this.dialogDetailReviewer = true

    },

    editReviewer(item){
      this.dataPegawai = item
      this.dialogPegawaiReviewer = true
    },
  },
}
</script>

<style lang="css" scoped>
</style>
